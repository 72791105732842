<template>
  <div class="adv__block">
    <v-container>
      <div class="my-10"></div>
      <h3>All Your Favorite Titles at the Click of a Button!</h3>
      <p>
        Get all your Academic and Trade title needs right here! Search, shop and check out with ease. DigiThis is the eBook department of the online book retailer, Books Direct. Offering a wide selection of both local and international eBook titles, DigiThis guarantees a vast shopping experience for all.
      </p>
      <div class="contact__plate">
        <div class="heading">
          Can't find what you're looking for?
        </div>
        <div class="address">
          <p class="intro mb-0">Get in contact with our offices and we'll help you out:
            <span>
              <a href="mailto:admin@booksdirect.co.za">admin@booksdirect.co.za</a>&nbsp;
              <a href="tel:021 701 4477">- 021 701 4477</a>
            </span>
          </p>


        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "BooksDirectAdv",
}
</script>
<style lang="scss">
.adv__block {
  background: #eee5e5 url("../assets/images/logomark-beige-01.svg") no-repeat;
  background-position: right -383px bottom -245px;
  background-size: 675px;
  width: 100%;
  padding-bottom: 2em;

  p {
    max-width: 780px;
  }

  h3 {
    font-weight: 100;
    font-size: 2em;
    word-spacing: 0.15em;
    padding-bottom: 1em;
  }


  .contact__plate {
    border: 2px solid black;
    border-radius: 2em;
    max-width: 450px;
    padding: 1.5em 2em;
    margin-top: 2em;

    .heading {
      font-weight: 600;
      font-size: 1.1em;
      padding-bottom: 1em;
    }

    a {
      text-decoration: none;
      color: #0d346d;
      &:nth-last-child(1) {
        color: #000;
      }
    }
  }
}

</style>
