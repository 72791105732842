<template>
  <v-card
    class="mx-5 pa-0 elevation-0 my-5"
    width="130px"
    height="200px"
    elevation="0"
  >
    <v-img
      :src="product.image_url"
      @click="handleCardClick"
      width="85px"
      class="mx-auto"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0  text-center"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-text @click="handleCardClick" class="cut-text pa-0"><a href="">{{ product.name }}</a></v-card-text>
    <v-card-subtitle class="pa-0 price text-center">
      R {{ product.price }}
    </v-card-subtitle>

  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LatestAndNewProductCardSlide",
  props: ['product'],
  data() {
    return {
      isFavorite: false
    }
  },
  computed: {
    ...mapState({
      favorites: state => {
        return state.favorites.items;
      },
    }),
  },
  watch: {
    favorites(newFavorites, oldFavorites) {
      this.defineIsInFavorites()
    }
  },
  mounted() {
    this.defineIsInFavorites()
  },
  methods: {
    handleCardClick() {
      this.$router.push({name: 'books.show', params: {slug: this.product.slug}})
    },
    async handleFavorite() {
      this.isFavorite
        ? await this.$store.dispatch('favorites/removeFromFavorites', {product: this.product}, {root: true})
        : await this.$store.dispatch('favorites/addToFavorites', {product: this.product}, {root: true})
    },
    defineIsInFavorites() {
      for (let i = 0; i < this.favorites.length; i++) {
        if (this.favorites[i].id === this.product.id) {
          this.isFavorite = true
          return
        }
      }
      this.isFavorite = false
    }
  }
}
</script>

<style scoped lang="scss">
.price {
  font-size: 1.2em;
  font-weight: bold;
}

.cut-text {
  width: 100%;
  font-size: .8em;
  text-align: center;
  a {
    text-decoration: none;
    color: #0d346d;
    font-weight: bolder;
  }
}

.card {
  text-align: center;
}

.w-100 {
  width: 100%;
}
</style>
