<template>
  <a :href="mainBanner.target_url">
    <v-img class="mx-auto" :src="mainBanner.image_url"></v-img>
  </a>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "HomeCarousel",
  computed: {
    ...mapState({
      'banners': state => state.banners.items.main
    }),
    mainBanner() {
      if (!  this.banners || typeof this.banners[0] == 'undefined') {
        return {}
      }
      return this.banners[0]
    }
  }
}
</script>

<style scoped>

</style>
