<template>
  <div>
    <vue-horizontal-list
      :items="products"
      :options="options"
      :style="$vuetify.breakpoint.smAndDown ? 'overflow:hidden' : ''"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          color="secondary lighten-5"
          elevation="0"
          fab
          v-bind="attrs"
          v-on="on"
          rounded
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:default="{ item }">
        <product-card-slide :product="item" v-if="item"></product-card-slide>
      </template>
<!--      <template v-slot:default="{ item }" v-if="false">
        <v-card
          class="mx-5 pa-0 elevation-0 transparent"
          width="180px"
          :height="$vuetify.breakpoint.mdAndUp ? '440px' : 'auto'"
          elevation="0"
          @click="$router.push({name: 'books.show', params: {slug: item.slug}})"
        >
          &lt;!&ndash;          <product-card-slide :product="item" />&ndash;&gt;
          <div class="image-container">
            <img :src="item.image_url"/>
          </div>


          <div class="cut-text">{{ item.name }}</div>
          <p class="pa-0">
            <del :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">was R
              <span>{{ (parseFloat(item.price) + parseFloat(item.price) * .2).toFixed(2) }}</span>
            </del>
            <span class="hidden-md-and-down">&nbsp;(20% off)</span>
          </p>

          <div class="subtitle">
            <div class="price">{{ item.price }}
              US
              <v-icon small>mdi-currency-usd</v-icon>
            </div>
          </div>

          <div class="mt-1">
            <v-chip
              color="#ddcecd"
              label
              small
            >
              <v-icon left small>
                mdi-medal-outline
              </v-icon>
              bestseller
            </v-chip>
          </div>

        </v-card>
      </template>-->
    </vue-horizontal-list>
  </div>
</template>

<script>
import VueHorizontalList from "vue-horizontal-list";
import ProductCardSlide from "../../components/products/ProductCardSlide";

export default {
  name: "image-four",
  components: {
    VueHorizontalList, ProductCardSlide
  },
  props: ['products'],
  data() {
    return {
      items: [],
      options: {
        responsive: [
          {end: 360, size: 2},
          {start: 360, end: 576, size: 3},
          {start: 576, end: 768, size: 4},
          {start: 768, end: 992, size: 5},
          {size: 5},
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
p {
  margin-top: 6px;

  & .sm {
    font-size: smaller;
  }
}

.vue-horizontal-list {
  .vhl-navigation {
    .vhl-btn-left, .vhl-btn-right {
      background: #c1c1c1 !important;

      svg, path {
        fill: white;
      }
    }
  }
}

.cut-text {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
}

@media (max-width: 400px) {
  .cut-text {
    display: block;
    text-overflow: unset;
    white-space: unset;
    //font-size: 3em;
  }
}

.price {
  font-size: 1.2em;
  font-weight: bold;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.image-container {
  border-radius: 3px;
  overflow: hidden;
  //height: 172px;
  display: flex;
  position: relative;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  //padding-top: 60%;
}

img {
  object-fit: cover;
  height: auto;
  width: 100%;
  //
  //object-fit: contain;
  //width: 90%;
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 5%;
  //right: 0;
}
</style>
