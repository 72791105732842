<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-slide-group
          class="pa-4"
          show-arrows
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn
              color="secondary lighten-5"
              elevation="0"
              fab small
              v-bind="attrs"
              v-on="on"
              rounded
            ><v-icon small>mdi-chevron-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn
              color="secondary lighten-5"
              elevation="0"
              fab small
              v-bind="attrs"
              v-on="on"
              rounded
            ><v-icon small>mdi-chevron-right</v-icon></v-btn>
          </template>

          <v-slide-item
            v-for="product in products"
            :key="product.id"
          >
            <product-card-slide :product="product"/>
          </v-slide-item>

        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCardSlide from "../../components/products/ProductCardSlide";

export default {
  name: "PopularProducts",
  components: {
    ProductCardSlide
  },
  data() {
    return {

	}
  },
  props: ['products'],
}
</script>

<style scoped>

</style>
