<template>
  <v-card rounded
          outlined
          elevation="1"
          class="pa-8 rounded-xl"
  >
    <v-container>
      <v-row>
        <v-col>
          <h3>Shop our selection</h3>
          <p><small>Do you dig these latest and greatest finds</small></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">

        </v-col>
      </v-row>
      <v-row  v-if="selections && selections.length > 0">
        <v-col>
          <a :href="choice.target_url"     :key="idx"  v-for="(choice,idx) in selections">
            <v-img
               :src="choice.image_url"
               width="100%"
               class="my-2"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

export default {
  name: "PopularProducts",
  components: {
  },
  computed: {
    ...mapState({
      'selections': state => state.banners.items.selections
    }),
  },
  data() {
      return {}
  },
  mounted() {
  },
  methods: {
/*    getSelections() {
      let images = [
        require('@/assets/images/selections/academic.png'),
        require('@/assets/images/selections/fiction.png'),
        require('@/assets/images/selections/non-fiction.png'),
      ]
      images.forEach(v => {
        this.selections.push({src:v})
      })

      // axios.get('/api/our_selections')
      //   .then(resp => {
      //     this.selections = resp.data.data;
      //   })
    },*/
  }
}
</script>

<style scoped>

</style>
