<template>
  <v-container>
    <v-row>
      <v-col cols="12">
          <v-tabs
            v-model="tab"
            grow
          >
            <v-tabs-slider color="#0d346d"></v-tabs-slider>

            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              active-class="black--text"
              @click="fetchTabProducts"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"
             v-for="product in items.slice(0,6)"
             :key="product.id"
      >
          <v-sheet

          >
            <latest-and-new-product-card-slide :product="product"/>
          </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Product from '@/models/Product';
import LatestAndNewProductCardSlide from "../../components/products/LatestAndNewProductCardSlide";

export default {
  name: "PopularProducts",
  components: {
    LatestAndNewProductCardSlide
  },
  props: ['products'],
  data(){
    return{
      items: [],
      bestsellers: [],
      tab:null,
      tabs:[
          {
              title: 'What`s new',
              model: () => this.items = this.products
          },
          {
              title: 'Top 20',
              model: () => this.items = this.products
          },
          {
              title: 'Most Popular',
              model: () => this.items = this.bestsellers
          }
      ]
    }
  },
    watch: {
        tab: function (val) {
            this.tabs[val].model()
        }
    },
    mounted() {
      this.items = this.products
      this.fetchBestsellers()
    },
    methods: {
        fetchBestsellers() {
            return Product.where('is_bestseller', 1).get().then(ApiResponse => {
                this.bestsellers = ApiResponse.getData();
                this.meta = ApiResponse.getMeta();
            });
        },
        fetchTabProducts() {
            this.tabs[this.tab].model()
        }
    }
}
</script>

<style scoped>
.v-tabs-slider{
  background-color: #0d346d;
}
.v-slide-group__content{
  justify-content: space-between;
}
</style>
