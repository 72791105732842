<template>
  <div>
    <v-container fluid>
      <home-carousel />
    </v-container>
    <v-container fluid class="mt-15">
      <state-indicate ref="indicate" v-slot:default="{isLoading}">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
        ></v-progress-linear>
        <v-row align="stretch">
          <v-col
            md="6"
            xl="2"
            lg="3"
            xs="12"
            class="py-5"
            v-for="i in 12"
            v-if="isLoading"
            :key="i + Math.random()"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              width="200"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-container>
            <v-row>
              <v-col cols="12"><h2>Popular right now</h2></v-col>
              <v-col cols="12">
                <products-slider v-if="bestsellers && bestsellers.length > 0" :products="bestsellers"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><h2>Discover latest and new items</h2></v-col>
              <v-col sm="12" md="7" class="d-none d-md-flex">
                <latest-and-new-products v-if="products && products.length > 0" :products="products"/>
              </v-col>
              <v-col sm="12" md="5" class="pa-5">
                <shop-our-selection-products />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><h2>Bestsellers / Most Popular</h2></v-col>
              <v-col cols="12">
                <products-slider v-if="bestsellers && bestsellers.length > 0" :products="bestsellers"/>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid><v-row><v-col class="my-5"></v-col></v-row></v-container>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <books-direct-adv/>
          </v-col>
        </v-row>


      </state-indicate>
      <v-pagination
        v-if="!loading"
        v-model="page"
        :length="meta.last_page"
        circle
        :total-visible="7"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
// import ApiTags from '../api/tags'
import Product from '@/models/Product';
import ProductsSlider from "../components/products/ProductsSlider";
import ProductCard from "../components/ProductCard";
import PopularProducts from "../components/products/PopularProducts";
import LatestAndNewProducts from "../components/products/LatestAndNewProducts";
import ShopOurSelectionProducts from "../components/products/ShopOurSelectionProducts";
import BooksDirectAdv from "../components/BooksDirectAdv";
import StateIndicate from "../components/utils/StateIndicate";
import HomeCarousel from "@/components/HomeCarousel";

export default {
  name: 'Home',
  components: {
    // Search,
    ProductsSlider,
    // ApiTags,
    PopularProducts, LatestAndNewProducts, ShopOurSelectionProducts, BooksDirectAdv,
    ProductCard, StateIndicate, HomeCarousel
  },
  data() {
    return {
      loading: true,
      search: '',
      products: [],
      bestsellers: [],
      meta: {},
      page: 1,
    }
  },

  watch: {
    async page() {
      this.$refs.indicate.usePromise(
        this.fetchProducts()
      )
    }
  },

  async mounted() {

    this.$refs.indicate.usePromise(
      this.fetchProducts()
    )

    this.$refs.indicate.usePromise(
      this.fetchBestsellers()
    )

    //   this.loading = false
    // @todo Example for saving and handling ApiResponse
    // @todo If successfully request, then 'a' = Product instance
    // @todo If not successfully, then We handle with 'catch' and 'a' = false
    /* let products = await new Product({}).save().catch(ApiResponse => {

       return false;
     });

    if (products) {
      // process
    }*/
  },

  methods: {
    // todo Move to store module logic and getting data and meta from store, check ApiResponse
    fetchProducts() {
      // todo For get model method, then and catch method for handling status request, or await promise, and handle with ApiResponse 'successfully method'
      return Product.page(this.page).get().then(ApiResponse => {
        this.products = ApiResponse.getData();
        this.meta = ApiResponse.getMeta();
      });
    },
      fetchBestsellers() {
          return Product.page(this.page).where('is_bestseller', 1).get().then(ApiResponse => {
              this.bestsellers = ApiResponse.getData();
              this.meta = ApiResponse.getMeta();
          });
      }
  },
}
</script>
